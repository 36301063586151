import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_BASE_API

class AccountService {   
	get() {
		return axios.get(API_URL + 'account', { headers: authHeader() });
    }
   
    add(account) {
        return axios.post(API_URL + 'account/add', account, { headers: authHeader() });
    }
    update(account) {
        return axios.post(API_URL + 'account', account, { headers: authHeader() });
    }
    getmlsboard() {
		return axios.get(API_URL + 'getmlsboard', { headers: authHeader() });
    }
	updatemlsboard(profileid, profile) {
        return axios.post(API_URL + 'updatemlsboard/'+profileid, profile, { headers: authHeader() });
    }
}
export default new AccountService();